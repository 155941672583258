import * as React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface StochasticMailingListFiltersProps {
  filters: {
    week?: number;
    year?: number;
  };
  onFilterChange: (key: string, value: number) => void;
}

const StochasticMailingListFilters: React.FC<
  StochasticMailingListFiltersProps
> = ({ filters, onFilterChange }) => {
  const currentDate = new Date();
  const systemYear = currentDate.getFullYear();
  const currentWeek = filters.week ?? 1;
  const currentYear = filters.year ?? systemYear;
  const yearOptions = [systemYear - 1, systemYear, systemYear + 1];
  const weekOptions = Array.from({ length: 52 }, (_, i) => i + 1);

  return (
    <div className="mb-4 flex space-x-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Year
        </label>
        <Select
          onValueChange={(value) => onFilterChange("year", Number(value))}
          value={String(currentYear)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select a year" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Years</SelectLabel>
              {yearOptions.map((year) => (
                <SelectItem key={year} value={String(year)}>
                  {year}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Week
        </label>
        <Select
          onValueChange={(value) => onFilterChange("week", Number(value))}
          value={String(currentWeek)}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select a week" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Weeks</SelectLabel>
              {weekOptions.map((week) => (
                <SelectItem key={week} value={String(week)}>
                  {week}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default StochasticMailingListFilters;
