import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  EntityState,
} from "@reduxjs/toolkit";
import { ApiCourse } from "@/api/fetchCourses/types";
import { ApiCourseType } from "@/api/fetchCourseTypes/types";
import { RootState } from "@/app/rootReducer";
import {
  fetchCoursesAction,
  createCourseAction,
  editCourseAction,
  duplicateCourseAction,
  deleteCourseAction,
  createCourseTypeAction,
  editCourseTypeAction,
  deleteCourseTypeAction,
} from "../thunks/courseThunks";

// Create entity adapter for courses
const coursesAdapter = createEntityAdapter<ApiCourse>();

// create entity adapter for course types
const courseTypesAdapter = createEntityAdapter<ApiCourseType>();

// Get the selectors
const selectors = coursesAdapter.getSelectors<RootState>(
  (state) => state.courses,
);

// Export selectors
export const {
  selectAll: selectAllCourses,
  selectById: selectCourseById,
  selectIds: selectCourseIds,
} = selectors;

export const selectTotalCount = (state: RootState) => state.courses.totalCount;

// Define the state shape for course data
interface CoursesState {
  loading: boolean;
  error: string | null;
  totalCount: number;
  selectedIds: number[];
  dialog: {
    isSubmitting: boolean;
    submitError: string | null;
    showUnsavedChangesDialog: boolean;
    showResetDialog: boolean;
    courseTypes: ApiCourseType[];
    isLoadingCourseTypes: boolean;
  };
  courseTypes: EntityState<ApiCourseType, number>;
}

// Initial state using the adapter
const initialState = coursesAdapter.getInitialState<CoursesState>({
  loading: false,
  error: null,
  totalCount: 0,
  selectedIds: [],
  dialog: {
    isSubmitting: false,
    submitError: null,
    showUnsavedChangesDialog: false,
    showResetDialog: false,
    courseTypes: [],
    isLoadingCourseTypes: false,
  },
  courseTypes: courseTypesAdapter.getInitialState(),
});

// Create the slice
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    toggleCourseSelection: (state, action: PayloadAction<number>) => {
      const courseId = action.payload;
      const index = state.selectedIds.indexOf(courseId);
      if (index === -1) {
        state.selectedIds.push(courseId);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    setSelectedCourses: (state, action: PayloadAction<number[]>) => {
      state.selectedIds = action.payload;
    },
    clearSelectedCourses: (state) => {
      state.selectedIds = [];
    },
    setDialogSubmitting: (state, action: PayloadAction<boolean>) => {
      state.dialog.isSubmitting = action.payload;
    },
    setDialogSubmitError: (state, action: PayloadAction<string | null>) => {
      state.dialog.submitError = action.payload;
    },
    setDialogUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.dialog.showUnsavedChangesDialog = action.payload;
    },
    setDialogReset: (state, action: PayloadAction<boolean>) => {
      state.dialog.showResetDialog = action.payload;
    },
    setDialogCourseTypes: (state, action: PayloadAction<ApiCourseType[]>) => {
      state.dialog.courseTypes = action.payload;
    },
    setDialogLoadingCourseTypes: (state, action: PayloadAction<boolean>) => {
      state.dialog.isLoadingCourseTypes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch courses
      .addCase(fetchCoursesAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoursesAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.totalCount = action.payload.meta?.totalCount || 0;
        coursesAdapter.setAll(state, action.payload.data.items);
      })
      .addCase(fetchCoursesAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch courses";
      })
      // Create course
      .addCase(createCourseAction.fulfilled, (state, action) => {
        if (!action.payload.id) {
          return; // Skip adding if id is null
        }

        // Get the original request from meta arg
        const request = action.meta.arg;

        const course: ApiCourse = {
          id: action.payload.id,
          courseCode: request.courseCode,
          courseName: action.payload.courseName || "",
          courseDescription: action.payload.courseDescription || "",
          coursePrice: action.payload.coursePrice || 0,
          imageUrl: action.payload.imageUrl || null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          courseCategoryId: action.payload.courseCategoryId || null,
        };
        coursesAdapter.addOne(state, course);
      })
      // Edit course
      .addCase(editCourseAction.fulfilled, (state, action) => {
        coursesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // Duplicate course
      .addCase(duplicateCourseAction.fulfilled, (state, action) => {
        if (action.payload && action.payload.id) {
          coursesAdapter.addOne(state, action.payload);
        }
      })
      // Delete course
      .addCase(deleteCourseAction.fulfilled, (state, action) => {
        coursesAdapter.removeOne(state, action.payload.courseId);
      })
      // Create course type
      .addCase(createCourseTypeAction.fulfilled, (state, action) => {
        if (!action.payload.id) return;
        courseTypesAdapter.addOne(state.courseTypes, action.payload);
      })
      // edit course type
      .addCase(editCourseTypeAction.fulfilled, (state, action) => {
        courseTypesAdapter.updateOne(state.courseTypes, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      // delete course type
      .addCase(deleteCourseTypeAction.fulfilled, (state, action) => {
        courseTypesAdapter.removeOne(state.courseTypes, action.payload.id);
      });
  },
});

// Export actions
export const {
  toggleCourseSelection,
  setSelectedCourses,
  clearSelectedCourses,
  setDialogSubmitting,
  setDialogSubmitError,
  setDialogUnsavedChanges,
  setDialogReset,
  setDialogCourseTypes,
  setDialogLoadingCourseTypes,
} = coursesSlice.actions;

// Add new selector
export const selectCourseDialogState = (state: RootState) =>
  state.courses.dialog;

export default coursesSlice.reducer;
