import { useMemo } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { ApiCourse } from "@/api/fetchCourses/types";
import { CourseManagementActionMenu } from "../components/CourseManagementActionMenu/CourseManagementActionMenu";
import { UseTableColumnsProps } from "../types/types";

export const useTableColumns = ({
  selectedCourses,
  onSelectCourse,
  onEditCourse,
  onDuplicateCourse,
  onDeleteCourse,
  onSelectAll,
  isAllSelected,
}: UseTableColumnsProps) => {
  return useMemo(
    () => [
      {
        id: "select",
        header: () => (
          <div className="px-1">
            <Checkbox
              checked={isAllSelected}
              className="text-white"
              onCheckedChange={onSelectAll}
            />
          </div>
        ),
        cell: ({
          row: { original: course },
        }: {
          row: { original: ApiCourse };
        }) => (
          <div className="px-1">
            <Checkbox
              checked={selectedCourses.includes(course.id)}
              className="text-white"
              onCheckedChange={(checked) =>
                onSelectCourse(course.id, !!checked)
              }
            />
          </div>
        ),
      },
      {
        accessorKey: "courseName",
        header: "Course Name",
      },
      {
        accessorKey: "courseCode",
        header: "Course Code",
      },
      {
        accessorKey: "courseType",
        header: "Course Type",
        cell: ({
          row: { original: course },
        }: {
          row: { original: ApiCourse };
        }) => {
          if (course.courseType) {
            return <span>{course.courseType}</span>;
          }

          if (course.courseTypeId) {
            return <span>Type ID: {course.courseTypeId}</span>;
          }

          return <span>—</span>;
        },
      },
      {
        accessorKey: "courseDescription",
        header: "Description",
      },
      {
        accessorKey: "coursePrice",
        header: "Price",
      },
      {
        id: "actions",
        cell: ({
          row: { original: course },
        }: {
          row: { original: ApiCourse };
        }) => (
          <CourseManagementActionMenu
            course={course}
            onDelete={onDeleteCourse}
            onDuplicate={onDuplicateCourse}
            onEdit={onEditCourse}
          />
        ),
      },
    ],
    [
      selectedCourses,
      onSelectCourse,
      onEditCourse,
      onDuplicateCourse,
      onDeleteCourse,
    ],
  );
};
