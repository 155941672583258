import { axiosInstance } from "../axiosInstance";
import { EditCourseResponse, EditCourseDTO } from "./types";

export const editCourse = async (
  id: number,
  editCourseDTO: EditCourseDTO,
): Promise<EditCourseResponse> => {
  const response = await axiosInstance.put<EditCourseResponse>(
    `/api/private/courses/${id}/edit`,
    editCourseDTO,
  );
  return response.data;
};
