import {
  coachingNavigation,
  hubNavigation,
  trainingPortalNavigation,
  stochasticNavigation,
  skinnyNavigation,
  adminNavigation,
} from "./navigationData";
import { NavigationItem } from "./types";
import permissionsService from "../../services/permissionsService";

export function getNavigation(
  section: string | null,
  currentPath: string,
): NavigationItem[] {
  const { hasPermission } = permissionsService();

  let navigation: NavigationItem[];

  switch (section) {
    case "hub":
      navigation = filterNavigationByPermission(hubNavigation, hasPermission);
      break;
    case "coaching":
      navigation = filterNavigationByPermission(
        coachingNavigation,
        hasPermission,
      );
      break;
    case "training-portal":
      navigation = filterNavigationByPermission(
        trainingPortalNavigation,
        hasPermission,
      );
      break;
    case "stochastic":
      navigation = filterNavigationByPermission(
        stochasticNavigation,
        hasPermission,
      );
      break;
    case "admin":
      navigation = filterNavigationByPermission(adminNavigation, hasPermission);
      break;
    default:
      navigation = [];
      break;
  }

  return updateCurrentState(navigation, currentPath);
}

function filterNavigationByPermission(
  items: NavigationItem[],
  hasPermission: (permission: string) => boolean,
): NavigationItem[] {
  return items
    .filter((item) => {
      if (item.permissions && item.permissions.length > 0) {
        return item.permissions.every((permission) =>
          hasPermission(permission),
        );
      }
      return true;
    })
    .map((item) => ({
      ...item,
      children: item.children
        ? filterNavigationByPermission(item.children, hasPermission)
        : undefined,
    }));
}

function updateCurrentState(
  items: NavigationItem[],
  currentPath: string,
): NavigationItem[] {
  return items.map((item) => {
    const isCurrent = item.children
      ? item.children.some((child) => child.href === currentPath) // Mark parent current if one of its children is current
      : item.href === currentPath; // Exact match for leaf nodes

    return {
      ...item,
      current: isCurrent,
      children: item.children
        ? updateCurrentState(item.children, currentPath)
        : undefined,
    };
  });
}

export function getSkinnyNavigation(currentPath: string): NavigationItem[] {
  const { hasApplicationAccess } = permissionsService();

  const filteredNavigation = skinnyNavigation.filter((item) => {
    // if (item.internalName === "coaching" && !hasApplicationAccess("coaching")) {
    //   return false;
    // }
    if (
      item.internalName === "training_portal" &&
      !hasApplicationAccess("training_portal")
    ) {
      return false;
    }
    if (item.internalName === "hub" && !hasApplicationAccess("hub")) {
      return false;
    }
    if (
      item.internalName === "partner_network" &&
      !hasApplicationAccess("partner_network")
    ) {
      return false;
    }
    if (
      item.internalName === "stochastic" &&
      !hasApplicationAccess("stochastic")
    ) {
      return false;
    }
    if (
      item.internalName === "scoreboard" &&
      !hasApplicationAccess("scoreboard")
    ) {
      return false;
    }
    return true;
  });

  return filteredNavigation.map((item) => ({
    ...item,
    current: currentPath.startsWith(item.href),
  }));
}

export function getSectionTitle(section: string | null): string {
  switch (section) {
    case "hub":
      return "Hub";
    case "coaching":
      return "Coaching";
    case "training-portal":
      return "Training Portal";
    case "stochastic":
      return "Stochastic";
    default:
      return "Certain Path";
  }
}
