export const COURSE_FORM = {
  BUTTON_TEXT: {
    add: "Add Course",
    edit: "Save Changes",
    adding: "Adding...",
    saving: "Saving...",
    cancel: "Cancel",
    discard: "Discard Changes",
    reset: "Reset Form",
  },
  DEFAULT_VALUES: {
    courseName: "",
    courseCode: "",
    courseDescription: "",
    coursePrice: 0,
    courseTypeId: null,
  },
  LABELS: {
    courseName: "Course Name",
    courseCode: "Course Code",
    courseDescription: "Description",
    coursePrice: "Price",
    courseType: "Course Type",
  },
  PLACEHOLDERS: {
    courseName: "Enter course name",
    courseCode: "Enter course code",
    courseDescription: "Enter course description",
    coursePrice: "Enter course price",
  },
  TITLES: {
    add: "Add New Course",
    edit: "Edit Course",
    unsavedChanges: "Unsaved Changes",
    resetForm: "Reset Form",
  },
  ERROR_MESSAGES: {
    title: "Error",
    default: "An error occurred while saving the course",
  },
  MESSAGES: {
    unsavedChanges:
      "You have unsaved changes. Are you sure you want to discard them?",
    resetForm:
      "Are you sure you want to reset the form? All changes will be lost.",
  },
  SHEET_WIDTH: "sm:max-w-[425px]",
} as const;

export const TOAST_MESSAGES = {
  FETCH_ERROR: {
    title: "Error",
    description: "Failed to fetch courses",
  },
  SUBMIT_ERROR: {
    title: "Error",
    description: "Failed to submit course",
  },
} as const;

export const DEFAULTS = {
  PAGE_SIZE: 10,
} as const;

export const FILTER_SECTIONS = {
  CATEGORIES: {
    title: "Category",
    type: "category",
  },
  STATUS: {
    title: "Status",
    type: "status",
    options: [
      { label: "Published", value: "Published" },
      { label: "Draft", value: "Draft" },
    ],
  },
};

export const DEFAULT_PAGE_SIZE = 10;
