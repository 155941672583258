import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const adminNavigation: NavigationItem[] = [
  {
    name: "Company Management",
    href: "/admin/company-management",
    icon: BuildingOffice2Icon,
    current: false,
    internalName: "company_management",
    permissions: ["CAN_MANAGE_COMPANY_ALL"],
  },
];

export default adminNavigation;
