"use client";

import React from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Mail, Info } from "lucide-react"; // <-- Import Info icon
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { TooltipProvider } from "@/components/ui/tooltip";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useCreateEditCampaign } from "@/modules/stochastic/features/CampaignManagement/hooks/useCreateEditCampaign";

function CreateCampaignPage() {
  const {
    formData,
    loadingCampaignDetailsMetadata,
    loadingCreate,
    errorCampaignDetailsMetadata,
    loadingAggregatedProspects,
    errorAggregatedProspects,
    showExclusionFilters,
    isFiltering,
    filtersDirty,
    filtersApplied,
    dateError,
    handleInputChange,
    handleFilterChange,
    handleZipCodeChange,
    applyFilters,
    handleSubmit,
    toggleMailingWeek,
    getTotalProspects,
    getProspectsPerMailing,
    hasAppliedFilters,
    campaignDetailsMetadata,
  } = useCreateEditCampaign();

  const mailingFrequencies = campaignDetailsMetadata?.mailingFrequencies || [];
  const campaignTargets = campaignDetailsMetadata?.campaignTargets || [];
  const customerRestrictionCriteria =
    campaignDetailsMetadata?.customerRestrictionCriteria || [];
  const netWorthOptions = campaignDetailsMetadata?.netWorth || [];

  return (
    <TooltipProvider>
      <div className="max-w-4xl mx-auto p-4">
        {/* Show loading/error states */}
        {loadingCampaignDetailsMetadata && (
          <div className="mb-4 text-blue-500">Loading metadata...</div>
        )}
        {errorCampaignDetailsMetadata && (
          <div className="mb-4 text-red-500">
            Error: {errorCampaignDetailsMetadata}
          </div>
        )}
        {errorAggregatedProspects && (
          <div className="mb-4 text-red-500">
            Error fetching prospects: {errorAggregatedProspects}
          </div>
        )}

        <form className="space-y-8" onSubmit={handleSubmit}>
          <Card>
            <CardHeader>
              <CardTitle>Create New Campaign</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              {/* Campaign Details */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Campaign Details</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label>Campaign Name</Label>
                    <Input
                      onChange={(e) =>
                        handleInputChange("campaignName", e.target.value)
                      }
                      placeholder="Enter campaign name"
                      value={formData.campaignName}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>Phone Number</Label>
                    <Input
                      onChange={(e) =>
                        handleInputChange("phoneNumber", e.target.value)
                      }
                      placeholder="444-444-4444"
                      value={formData.phoneNumber}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>Description</Label>
                  <Input
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    placeholder="Short campaign description"
                    value={formData.description}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label>Start Date</Label>
                    <Input
                      onChange={(e) =>
                        handleInputChange("startDate", e.target.value)
                      }
                      type="date"
                      value={formData.startDate}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label>End Date</Label>
                    <Input
                      onChange={(e) =>
                        handleInputChange("endDate", e.target.value)
                      }
                      type="date"
                      value={formData.endDate}
                    />
                    {/* Show date validation error if any */}
                    {dateError && (
                      <p className="text-red-600 text-sm mt-1">{dateError}</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Mailing Schedule */}
              <Card>
                <CardHeader>
                  <CardTitle>Mailing Schedule</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-6">
                    <div className="space-y-4">
                      <div className="flex items-center gap-2">
                        <Label>Mailing Frequency</Label>
                        <Select
                          onValueChange={(value) =>
                            handleInputChange("mailingFrequency", value)
                          }
                          value={formData.mailingFrequency}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select campaign duration" />
                          </SelectTrigger>
                          <SelectContent className="bg-white">
                            {mailingFrequencies.map((mf) => (
                              <SelectItem
                                key={mf.value}
                                value={mf.value.toString()}
                              >
                                {mf.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        {/* Show info icon & tooltip only if there's a mailing frequency */}
                        {parseInt(formData.mailingFrequency) > 0 && (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <span>
                                <Info className="w-5 h-5 text-gray-500 cursor-pointer" />
                              </span>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="text-sm">
                                Number of mailings sent within each frequency
                                cycle
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        )}
                      </div>
                    </div>

                    {parseInt(formData.mailingFrequency) > 0 && (
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4">
                        {Array.from(
                          {
                            length: parseInt(formData.mailingFrequency) || 0,
                          },
                          (_, index) => (
                            <div
                              className={`
                                p-4 rounded-lg border cursor-pointer transition-all
                                ${
                                  formData.selectedMailingWeeks.includes(index)
                                    ? "bg-blue-100 border-blue-500"
                                    : "hover:bg-gray-50"
                                }
                              `}
                              key={index}
                              onClick={() => toggleMailingWeek(index)}
                            >
                              <div className="flex flex-col items-center space-y-2">
                                <Mail
                                  className={`w-6 h-6 ${
                                    formData.selectedMailingWeeks.includes(
                                      index,
                                    )
                                      ? "text-blue-600"
                                      : "text-gray-400"
                                  }`}
                                />
                                <div className="text-sm font-medium">
                                  Week {index + 1}
                                </div>
                                {formData.selectedMailingWeeks.includes(
                                  index,
                                ) &&
                                  hasAppliedFilters() && (
                                    <div className="text-xs text-blue-600 font-medium">
                                      {getProspectsPerMailing().toLocaleString()}{" "}
                                      mailings
                                    </div>
                                  )}
                                {formData.selectedMailingWeeks.includes(
                                  index,
                                ) &&
                                  !hasAppliedFilters() && (
                                    <div className="text-xs text-gray-500">
                                      Apply filters first
                                    </div>
                                  )}
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    )}

                    <div className="mt-6">
                      {hasAppliedFilters() ? (
                        <div className="text-sm text-gray-500">
                          Total Prospects:{" "}
                          {getTotalProspects().toLocaleString()}
                        </div>
                      ) : (
                        <div className="text-sm text-gray-500">
                          Apply filters to see total prospects
                        </div>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Campaign Target Selection */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  Select your Campaign Target
                </h3>
                <RadioGroup
                  className="space-y-2"
                  onValueChange={(value) =>
                    handleFilterChange("audience", value)
                  }
                  value={formData.filterCriteria.audience}
                >
                  {campaignTargets.map((target) => (
                    <div
                      className="flex items-center space-x-2"
                      key={target.value}
                    >
                      <RadioGroupItem id={target.value} value={target.value} />
                      <Label htmlFor={target.value}>{target.name}</Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>

              {/* Demographic Targets */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">
                  Select your Demographic Targets
                </h3>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  <div className="space-y-2">
                    <Label>Prospect Age Range</Label>
                    <div className="flex gap-2">
                      <Input
                        onChange={(e) =>
                          handleFilterChange("prospectAge", {
                            ...formData.filterCriteria.prospectAge,
                            min: e.target.value,
                          })
                        }
                        placeholder="Min"
                        type="number"
                        value={formData.filterCriteria.prospectAge.min}
                      />
                      <Input
                        onChange={(e) =>
                          handleFilterChange("prospectAge", {
                            ...formData.filterCriteria.prospectAge,
                            max: e.target.value,
                          })
                        }
                        placeholder="Max"
                        type="number"
                        value={formData.filterCriteria.prospectAge.max}
                      />
                    </div>
                  </div>

                  <div className="space-y-2">
                    <Label>Minimum Net Worth</Label>
                    <Select
                      onValueChange={(value) =>
                        handleFilterChange("netWorth", value)
                      }
                      value={formData.filterCriteria.netWorth}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select minimum net worth" />
                      </SelectTrigger>
                      <SelectContent className="bg-white">
                        {netWorthOptions.map((nw) => (
                          <SelectItem
                            key={nw.value}
                            value={nw.value.toString()}
                          >
                            {nw.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <Label>Minimum Home Age</Label>
                    <Input
                      onChange={(e) =>
                        handleFilterChange("homeAge", e.target.value)
                      }
                      placeholder="Enter years"
                      type="number"
                      value={formData.filterCriteria.homeAge}
                    />
                  </div>
                </div>
              </div>

              {/* Existing Customer Restriction Criteria (hidden if audience === "include_prospects_only") */}
              {showExclusionFilters && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">
                    Existing Customer Restriction Criteria
                  </h3>
                  <div className="grid grid-cols-2 gap-4">
                    {customerRestrictionCriteria.map((c) => (
                      <div
                        className="flex items-center space-x-2"
                        key={c.value}
                      >
                        <Checkbox
                          checked={
                            (c.value === "exclude_club_members" &&
                              formData.filterCriteria.excludeClubMembers) ||
                            (c.value === "exclude_ltv_greater_5000" &&
                              formData.filterCriteria.excludeLTV) ||
                            (c.value === "exclude_installations" &&
                              formData.filterCriteria.excludeInstallCustomers)
                          }
                          id={c.value}
                          onCheckedChange={(checked) => {
                            if (c.value === "exclude_club_members") {
                              handleFilterChange("excludeClubMembers", checked);
                            } else if (c.value === "exclude_ltv_greater_5000") {
                              handleFilterChange("excludeLTV", checked);
                            } else if (c.value === "exclude_installations") {
                              handleFilterChange(
                                "excludeInstallCustomers",
                                checked,
                              );
                            }
                          }}
                        />
                        <Label htmlFor={c.value}>{c.name}</Label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Filter Actions */}
              <div className="flex justify-end items-center gap-4">
                {filtersDirty && (
                  <span className="text-yellow-600 text-sm">
                    Filters have changed. Please reapply filters to update
                    prospect counts.
                  </span>
                )}
                <Button
                  disabled={isFiltering || loadingCampaignDetailsMetadata}
                  onClick={applyFilters}
                  type="button"
                  variant={filtersDirty ? "default" : "outline"}
                >
                  {isFiltering || loadingAggregatedProspects
                    ? "Applying Filters..."
                    : "Apply Filters"}
                </Button>
              </div>

              {/* Zip Codes */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Select Postal Codes</h3>
                <div className="border rounded-lg">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Postal Code</TableHead>
                        <TableHead>Avg Sales</TableHead>
                        <TableHead>Households</TableHead>
                        <TableHead className="text-right">
                          Select Prospects
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {formData.zipCodes.map((zipCode, index) => (
                        <TableRow key={zipCode.code}>
                          <TableCell>{zipCode.code}</TableCell>
                          <TableCell>
                            ${zipCode.avgSale.toLocaleString()}
                          </TableCell>
                          <TableCell>
                            {isFiltering || loadingAggregatedProspects ? (
                              <span className="text-gray-400">
                                Calculating...
                              </span>
                            ) : (
                              zipCode.filteredProspects.toLocaleString()
                            )}
                          </TableCell>
                          <TableCell className="text-right">
                            <div className="flex items-center justify-end gap-2">
                              <Input
                                className="w-24"
                                disabled={!filtersApplied || filtersDirty}
                                max={zipCode.filteredProspects}
                                min={0}
                                onChange={(e) =>
                                  handleZipCodeChange(index, e.target.value)
                                }
                                type="number"
                                value={zipCode.selectedProspects}
                              />
                              <div className="flex gap-1">
                                <Button
                                  disabled={!filtersApplied || filtersDirty}
                                  onClick={() => handleZipCodeChange(index, "")}
                                  size="sm"
                                  type="button"
                                  variant="outline"
                                >
                                  Clear
                                </Button>
                                <Button
                                  disabled={!filtersApplied || filtersDirty}
                                  onClick={() =>
                                    handleZipCodeChange(
                                      index,
                                      zipCode.filteredProspects.toString(),
                                    )
                                  }
                                  size="sm"
                                  type="button"
                                  variant="outline"
                                >
                                  Max
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>

              {/* Submit / Cancel */}
              <CardFooter className="flex justify-end space-x-4">
                <Button type="button" variant="outline">
                  Cancel
                </Button>
                <Button
                  disabled={
                    !filtersApplied ||
                    filtersDirty ||
                    isFiltering ||
                    !formData.zipCodes.some(
                      (zip) => zip.selectedProspects !== "",
                    )
                  }
                  type="submit"
                >
                  {loadingCreate ? "Submitting..." : "Create Campaign"}
                </Button>
              </CardFooter>
            </CardContent>
          </Card>
        </form>
      </div>
    </TooltipProvider>
  );
}

export default CreateCampaignPage;
