import {
  UsersIcon,
  EyeIcon,
  ChartBarIcon,
  MegaphoneIcon,
  PlusIcon,
  ArrowUpTrayIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const stochasticNavigation: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/stochastic/dashboard",
    icon: ChartBarIcon,
    current: false,
    internalName: "dashboard",
    permissions: [],
  },
  {
    name: "Prospects",
    href: "/stochastic/prospects",
    icon: EyeIcon,
    current: false,
    internalName: "prospects",
    permissions: ["CAN_MANAGE_PROSPECTS"],
  },
  {
    name: "Customers",
    href: "/stochastic/customers",
    icon: UsersIcon,
    current: false,
    internalName: "customers",
    permissions: ["CAN_MANAGE_CUSTOMERS"],
  },
  {
    name: "Do Not Mail",
    href: "/stochastic/do-not-mail",
    icon: EyeIcon,
    current: false,
    internalName: "doNotMail",
    permissions: ["CAN_MANAGE_DO_NOT_MAIL_LIST"],
  },
  {
    name: "Campaign Management",
    href: "/stochastic/campaigns",
    icon: MegaphoneIcon,
    current: false,
    internalName: "campaigns",
    permissions: ["CAN_MANAGE_CAMPAIGNS"],
  },
  {
    name: "Stochastic Mailing",
    href: "/stochastic/mailing",
    icon: EnvelopeIcon,
    current: false,
    internalName: "stochastic_mailing",
    permissions: ["CAN_VIEW_STOCHASTIC_MAILING"],
  },
  {
    name: "Uploads",
    href: "#",
    icon: ArrowUpTrayIcon,
    current: false,
    internalName: "dashboards",
    permissions: [],
    children: [
      {
        name: "Field Service Import",
        href: "/stochastic/field-service-import",
        icon: PlusIcon,
        current: false,
        internalName: "field_service_import",
        permissions: [],
      },
      {
        name: "Prospect Source Import",
        href: "/stochastic/prospect-source-import",
        icon: PlusIcon,
        current: false,
        internalName: "prospect_source_import",
        permissions: [],
      },
    ],
  },
];

export default stochasticNavigation;
