import { ChartBarIcon } from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const coachingNavigation: NavigationItem[] = [
  {
    name: "Dashboard",
    href: "/coaching",
    icon: ChartBarIcon,
    current: false,
    internalName: "coaching",
    permissions: [],
  },
];

export default coachingNavigation;
