import { Route } from "react-router-dom";
import MainLayout from "../../components/MainLayout/MainLayout";
import { AuthenticationGuard } from "../../components/AuthenticationGuard/AuthenticationGuard";
import CompanyManagement from "@/modules/hub/features/CompanyManagement/components/CompanyList/CompanyList";
const AdminRoutes = (
  <Route
    element={
      <AuthenticationGuard component={() => <MainLayout section="admin" />} />
    }
    path="/admin"
  >
    <Route element={<CompanyManagement />} index />
    <Route element={<CompanyManagement />} path="/admin/company-management" />
  </Route>
);

export default AdminRoutes;
