import {
  BuildingLibraryIcon,
  AcademicCapIcon,
  PuzzlePieceIcon,
  ReceiptPercentIcon,
  TrophyIcon,
  Cog8ToothIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { NavigationItem } from "../types";

const skinnyNavigation: NavigationItem[] = [
  {
    name: "Hub",
    internalName: "hub",
    href: "/hub",
    icon: BuildingLibraryIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Training Portal",
    internalName: "training_portal",
    href: "/training-portal",
    icon: AcademicCapIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Stochastic",
    internalName: "stochastic",
    href: "/stochastic",
    icon: PuzzlePieceIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Coaching",
    internalName: "coaching",
    href: "/coaching",
    icon: ChartBarIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Partner Network",
    internalName: "partner_network",
    href: "#",
    icon: ReceiptPercentIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Scoreboard",
    internalName: "scoreboard",
    href: "#",
    icon: TrophyIcon,
    current: false,
    permissions: [],
  },
  {
    name: "Admin",
    internalName: "admin",
    href: "/admin",
    icon: Cog8ToothIcon,
    current: false,
    permissions: ["CAN_MANAGE_COMPANY_ALL"],
  },
];

export default skinnyNavigation;
