import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/Card/Card";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { useTheme } from "@/context/ThemeContext";
import { TotalSalesByYearAndMonthChartDataItem } from "@/api/fetchTotalSalesByYearAndMonthChartData/types";

const generateColors = (count: number, theme: "light" | "dark") => {
  const saturation = theme === "light" ? 70 : 80;
  const lightness = 60;
  const maxHues = 360;
  const primeStep = 137;

  return Array.from({ length: count }, (_, i) => {
    const hue = (i * primeStep) % maxHues;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  });
};

const extractYearKeysAndColors = (
  data: TotalSalesByYearAndMonthChartDataItem[],
  theme: "light" | "dark",
) => {
  if (!data || data.length === 0) return { yearKeys: [], colors: [] };

  const yearKeys = Object.keys(data[0]).filter((key) => key !== "month");
  const colors = generateColors(yearKeys.length, theme);

  return { yearKeys, colors };
};

const formatCurrency = (value: number): string =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

type TotalSalesByYearAndMonthChartProps = {
  chartId?: string;
  initialData: TotalSalesByYearAndMonthChartDataItem[];
};

export default function TotalSalesByYearAndMonthChart({
  initialData,
}: TotalSalesByYearAndMonthChartProps) {
  const { theme } = useTheme();
  const { yearKeys, colors } = extractYearKeysAndColors(initialData, theme);

  return (
    <Card className="w-full bg-white dark:bg-secondary-dark">
      <CardHeader>
        <CardTitle className="text-fontColor dark:text-light">
          Total Sales By Year & Month Chart
        </CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer height={500} width="100%">
          <BarChart
            data={initialData}
            margin={{ top: 20, right: 30, left: 45, bottom: 5 }}
          >
            <CartesianGrid
              stroke={theme === "dark" ? "#374151" : "#e5e7eb"}
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="month"
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
            />
            <YAxis
              stroke={theme === "dark" ? "#9CA3AF" : "#4B5563"}
              tickFormatter={formatCurrency}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme === "dark" ? "#1F2937" : "#FFFFFF",
                borderColor: theme === "dark" ? "#374151" : "#E5E7EB",
                color: theme === "dark" ? "#F3F4F6" : "#1F2937",
              }}
              formatter={formatCurrency}
            />
            <Legend />
            {yearKeys.map((year, index) => (
              <Bar dataKey={year} fill={colors[index]} key={year} name={year} />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
