import React, { useCallback, useMemo } from "react";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useStochasticMailingData } from "../../hooks/useStochasticMailingData";
import { createStochasticMailingColumns } from "../StochasticMailingListColumns/StochasticMailingListColumns";
import StochasticMailingListFilters from "../StochasticMailingListFilters/StochasticMailingListFilters";

const StochasticMailingList: React.FC = () => {
  const {
    mailDataRows,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    filters,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleFilterChange,
  } = useStochasticMailingData();

  const columns = useMemo(() => createStochasticMailingColumns(), []);

  return (
    <MainPageWrapper
      error={error}
      loading={loading}
      title="Stochastic Mailing Data"
    >
      <StochasticMailingListFilters
        filters={{
          week: filters.week as number,
          year: filters.year as number,
        }}
        onFilterChange={(filterKey, value) => {
          handleFilterChange(filterKey, value);
          handlePaginationChange({
            pageIndex: 0,
            pageSize: pagination.pageSize,
          });
        }}
      />

      <DataTable
        columnVisibility={columnVisibility}
        columns={columns}
        data={mailDataRows}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        pageCount={Math.ceil(totalCount / pagination.pageSize)}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        sorting={sorting}
      />
    </MainPageWrapper>
  );
};

export default StochasticMailingList;
