import { useToast } from "@/hooks/useToast";

export const useToastSuccess = () => {
  const { toast } = useToast();

  const executeToastSuccess = async <T>(
    action: () => Promise<T>,
    successMessage: { title: string; description: string },
  ): Promise<T | undefined> => {
    const result = await action();

    toast({
      title: successMessage.title,
      description: successMessage.description,
      variant: "default",
      duration: 5000,
    });

    return result;
  };

  return { executeToastSuccess };
};
