import { FetchCourseTypesRequest, FetchCourseTypesResponse } from "./types";
import { axiosInstance } from "../axiosInstance";

export const fetchCourseTypes = async (
  requestData: FetchCourseTypesRequest,
): Promise<FetchCourseTypesResponse> => {
  const params = {
    ...requestData,
  };

  const response = await axiosInstance.get<FetchCourseTypesResponse>(
    "/api/private/course-types",
    {
      params,
    },
  );
  return response.data;
};
