import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { CourseFormField } from "../CourseFormField/CourseFormField";
import { EditCourseTypeDialogProps } from "../../types/types";
import { useEditCourseTypeForm } from "../../hooks/useEditCourseTypeForm";
import { CourseTypeFormData } from "../../schemas/courseSchema";
import { useToast } from "@/hooks/useToast";
import { useCallback } from "react";

export function CourseTypeDialog({
  courseType,
  open,
  onOpenChange,
  onSuccess,
}: EditCourseTypeDialogProps): JSX.Element {
  const { toast } = useToast();
  const { form, handleSubmit, isSubmitting } =
    useEditCourseTypeForm(courseType);

  const onSubmit = useCallback(
    async (data: CourseTypeFormData) => {
      try {
        const success = await handleSubmit(data);
        if (success) {
          form.reset();
          toast({
            title: "Success",
            description: `Course type ${courseType ? "updated" : "created"} successfully`,
          });
          onOpenChange(false);
          onSuccess();
        }
      } catch (error: unknown) {
        toast({
          variant: "destructive",
          title: "Error",
          description:
            error instanceof Error
              ? error.message
              : "An unexpected error occurred",
        });
      }
    },
    [courseType, handleSubmit, onOpenChange, onSuccess, toast, form],
  );

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        form.reset();
      }
      onOpenChange(open);
    },
    [onOpenChange, form],
  );

  return (
    <Dialog onOpenChange={handleOpenChange} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {courseType ? "Edit Course Type" : "Add Course Type"}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
            <CourseFormField
              control={form.control}
              label="Name"
              name="name"
              placeholder="Enter course type name"
            />

            <CourseFormField
              control={form.control}
              label="Description"
              name="description"
              placeholder="Enter course type description"
              type="textarea"
            />

            <CourseFormField
              control={form.control}
              label="Active"
              name="isActive"
              type="checkbox"
            />

            <div className="flex justify-end space-x-4 pt-4">
              <Button
                onClick={() => onOpenChange(false)}
                type="button"
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                className="min-w-[100px]"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
