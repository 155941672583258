import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCampaigns } from "../../hooks/useCampaigns";
import MainPageWrapper from "../../../../../../components/MainPageWrapper/MainPageWrapper";
import DataTable from "../../../../../../components/Datatable/Datatable";
import { useNavigate } from "react-router-dom";
import { createCampaignsColumns } from "../CampaignColumns/CampaignColumns";
import { Button } from "@/components/Button/Button";
import { CampaignStatus } from "@/api/fetchCampaignStatuses/types";
import { fetchCampaignStatuses } from "@/api/fetchCampaignStatuses/fetchCampaignStatusesApi";
import CampaignListFilters from "@/modules/stochastic/features/CampaignManagement/components/CampaignListFilters/CampaignListFilters";

const CampaignList: React.FC = () => {
  const navigate = useNavigate();
  const {
    campaigns,
    totalCount,
    loading,
    error,
    pagination,
    sorting,
    columnVisibility,
    handlePaginationChange,
    handleSortingChange,
    handleColumnVisibilityChange,
    handleCampaignStatusChange,
    campaignStatusId,
  } = useCampaigns();

  const [campaignStatuses, setCampaignStatuses] = useState<CampaignStatus[]>(
    [],
  );

  const handleViewBatches = useCallback(
    (id: number) => {
      navigate(`/stochastic/campaigns/${id}/batches`);
    },
    [navigate],
  );

  const handleViewFiles = useCallback(
    (id: number) => {
      navigate(`/stochastic/campaigns/${id}/files`);
    },
    [navigate],
  );

  const columns = useMemo(
    () =>
      createCampaignsColumns({
        handleViewBatches,
        handleViewFiles,
      }),
    [handleViewBatches, handleViewFiles],
  );

  // Fetch campaign statuses on mount
  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const response = await fetchCampaignStatuses();
        setCampaignStatuses(response.data);
      } catch (err) {
        console.error("Failed to fetch campaign statuses", err);
      }
    };
    loadStatuses();
  }, []);

  const handleFilterChange = useCallback(
    (newStatusId?: number) => {
      // This updates campaignStatusId inside the hook and resets pagination
      handleCampaignStatusChange(newStatusId);
    },
    [handleCampaignStatusChange],
  );

  return (
    <>
      <MainPageWrapper
        actions={
          <Button
            onClick={() => {
              navigate("/stochastic/campaigns/new");
            }}
          >
            Create Campaign
          </Button>
        }
        error={error}
        loading={loading}
        title="Campaigns"
      >
        <CampaignListFilters
          campaignStatusId={campaignStatusId}
          campaignStatuses={campaignStatuses}
          onFilterChange={handleFilterChange}
        />

        <DataTable
          columnVisibility={columnVisibility}
          columns={columns}
          data={campaigns}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          onPaginationChange={handlePaginationChange}
          onSortingChange={handleSortingChange}
          pageCount={Math.ceil(totalCount / pagination.pageSize)}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          sorting={sorting}
        />
      </MainPageWrapper>
    </>
  );
};

export default CampaignList;
