import axios from "../axiosInstance";
import { CreateCourseTypeRequest, CreateCourseTypeResponse } from "./types";

export const createCourseType = async (
  requestData: CreateCourseTypeRequest,
): Promise<CreateCourseTypeResponse> => {
  const response = await axios.post<CreateCourseTypeResponse>(
    `/api/private/course-types/create`,
    requestData,
  );
  return response.data;
};
