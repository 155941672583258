// Form imports
import { Control, Path, RegisterOptions } from "react-hook-form";
import { useState } from "react";

// UI Components
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";

// Hooks
import { useCourseFormField } from "../../hooks/useCourseFormField";

interface SelectOption {
  value: string;
  label: string | JSX.Element;
}

interface SelectProps {
  placeholder?: string;
  options: SelectOption[];
  onChange?: (event: { target: { value: string } }) => void;
  onValueChange?: (value: string) => void;
}

interface CourseFormFieldProps<T extends Record<string, unknown>> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder?: string;
  type?: "text" | "number" | "textarea" | "select" | "checkbox";
  id?: string;
  disabled?: boolean;
  readonly?: boolean;
  validation?: RegisterOptions<T, Path<T>>;
  description?: string;
  selectProps?: SelectProps;
}

export const CourseFormField = <T extends Record<string, unknown>>({
  control,
  name,
  label,
  placeholder,
  type = "text",
  id = `course-field-${String(name)}`,
  disabled = false,
  readonly = false,
  validation,
  description,
  selectProps,
}: CourseFormFieldProps<T>): JSX.Element => {
  const { validatedType } = useCourseFormField<T>({
    control,
    name,
    type: type === "select" ? "text" : type,
  });
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }): JSX.Element => {
        const handleFocus = () => {
          if (!disabled && !readonly) {
            setIsFocused(true);
          }
        };

        const handleBlur = () => {
          setIsFocused(false);
          field.onBlur();
        };

        const handleChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          if (!disabled && !readonly) {
            field.onChange(e);
          }
        };

        return (
          <FormItem>
            {type === "checkbox" ? (
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={Boolean(field.value)}
                  disabled={disabled}
                  id={id}
                  onCheckedChange={(checked) => field.onChange(checked)}
                />
                <FormLabel
                  className={cn(
                    "mb-0",
                    disabled && "cursor-not-allowed opacity-50",
                    isFocused && "text-primary",
                  )}
                  htmlFor={id}
                >
                  {label}
                </FormLabel>
              </div>
            ) : (
              <>
                <FormLabel
                  className={cn(
                    disabled && "cursor-not-allowed opacity-50",
                    isFocused && "text-primary",
                  )}
                  htmlFor={id}
                >
                  {label}
                </FormLabel>
                <FormControl>
                  {type === "select" && selectProps ? (
                    <Select
                      disabled={disabled}
                      onValueChange={(value) => {
                        field.onChange(value ? parseInt(value) : null);
                        selectProps.onValueChange?.(value);
                        // Simulate onChange event for compatibility
                        selectProps.onChange?.({ target: { value } });
                      }}
                      value={field.value?.toString() || ""}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={selectProps.placeholder} />
                      </SelectTrigger>
                      <SelectContent>
                        {selectProps.options.map((option: SelectOption) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : validatedType === "textarea" ? (
                    <Textarea
                      className={cn(
                        disabled && "cursor-not-allowed",
                        readonly &&
                          "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                        isFocused && "border-secondary",
                      )}
                      disabled={disabled}
                      id={id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      placeholder={placeholder}
                      readOnly={readonly}
                      value={String(field.value || "")}
                    />
                  ) : (
                    <Input
                      className={cn(
                        disabled && "cursor-not-allowed",
                        readonly &&
                          "bg-muted focus-visible:ring-0 focus-visible:ring-offset-0",
                        isFocused && "border-secondary",
                      )}
                      disabled={disabled}
                      id={id}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      placeholder={placeholder}
                      readOnly={readonly}
                      type={validatedType}
                      value={String(field.value || "")}
                    />
                  )}
                </FormControl>
                {description && (
                  <FormDescription className={cn(isFocused && "text-primary")}>
                    {description}
                  </FormDescription>
                )}
                <FormMessage />
              </>
            )}
          </FormItem>
        );
      }}
      rules={validation}
    />
  );
};
