import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/hooks/useToast";
import { useAppDispatch } from "@/app/hooks";
import {
  editCourseTypeAction,
  createCourseTypeAction,
} from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import { CourseTypeFormData, courseTypeSchema } from "../schemas/courseSchema";
import { ApiCourseType } from "@/api/fetchCourseTypes/types";

export const useEditCourseTypeForm = (courseType: ApiCourseType | null) => {
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<CourseTypeFormData>({
    resolver: zodResolver(courseTypeSchema),
    defaultValues: {
      name: courseType?.name ?? "",
      description: courseType?.description ?? null,
      isActive: courseType?.isActive ?? true,
    },
  });

  const handleSubmit = async (data: CourseTypeFormData) => {
    setIsSubmitting(true);
    try {
      if (courseType) {
        await dispatch(
          editCourseTypeAction({
            courseTypeId: courseType.id,
            editCourseTypeDTO: data,
          }),
        ).unwrap();
        toast({
          title: "Success",
          description: "Course type updated successfully",
        });
      } else {
        await dispatch(
          createCourseTypeAction({
            createCourseTypeDTO: data,
          }),
        ).unwrap();
        toast({
          title: "Success",
          description: "Course type created successfully",
        });
      }
      return true;
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
        variant: "destructive",
      });
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    form,
    handleSubmit,
    isSubmitting,
  };
};
