import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import MainPageWrapper from "@/components/MainPageWrapper/MainPageWrapper";
import { uploadProspectSource } from "@/api/uploadProspectSource/uploadProspectSourceApi";

const fileTypes = ["CSV", "XLSX", "XLS"];

const softwarePackages = [
  {
    value: "acxiom",
    label: "Acxiom List",
  },
];

const importTypes = [
  {
    value: "prospects",
    label: "Prospects",
    description:
      "Prospects are individuals or organizations that have the potential to become a customer.",
  },
];

interface ProspectSourceImportProps {
  onUploadComplete?: (jobId: string) => void;
}

const generateDefaultTag = (software: string, importType: string): string => {
  const today = new Date().toISOString().split('T')[0];
  return `${software}_${importType}_${today}`;
};

export const ProspectSourceImport: React.FC<ProspectSourceImportProps> = () => {
  const [selectedSoftware, setSelectedSoftware] = useState("acxiom");
  const [selectedImportType, setSelectedImportType] = useState("prospects");
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [customTag, setCustomTag] = useState(
    generateDefaultTag(selectedSoftware, selectedImportType)
  );


  const sanitizeTag = (tag: string): string => {
    return tag.toLowerCase().replace(/[^a-z0-9_-]/g, "_");
  };

  const handleFileChange = (file: File) => {
    setFile(file);
  };

  const handleUpload = async () => {
    if (!file || !selectedSoftware || !selectedImportType) {
      const message = "Please fill in all required fields.";
      toast.error(message);
      return;
    }

    const uploadProspectSourceDTO = {
      file,
      software: selectedSoftware,
      importType: selectedImportType,
      tag: sanitizeTag(customTag),
    };

    try {
      setUploadError(null);
      setUploadSuccess(false);
      setIsUploading(true);
      setUploadProgress(0);

      await uploadProspectSource(uploadProspectSourceDTO, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 1),
        );
        setUploadProgress(percentCompleted);
      });

      setUploadSuccess(true);
      setFile(null);
    } catch (error) {
      toast.error("An error occurred during file upload.");
      setUploadError("Failed to upload file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (isUploading || uploadSuccess || uploadError) {
      document
        .getElementById("upload-status")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isUploading, uploadSuccess, uploadError]);

  return (
    <MainPageWrapper
      error={null}
      loading={false}
      title="Prospect Source Import"
    >
      <div className="max-w-3xl p-8 bg-white">
        <h2 className="text-xl mb-6 text-gray-700">
          Import prospects from a data source like a purchased marketing list
        </h2>

        <label className="block mb-4">
          <span className="text-gray-600 font-medium">Select Data Source</span>
          <select
            className="block w-full p-3 mt-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            onChange={(e) => {
              setSelectedSoftware(e.target.value);
              setCustomTag(generateDefaultTag(e.target.value, selectedImportType));
            }}
            value={selectedSoftware}
          >
            {softwarePackages.map((software) => (
              <option key={software.value} value={software.value}>
                {software.label}
              </option>
            ))}
          </select>
        </label>

        <div className="mb-6">
          <h2 className="text-gray-600 font-medium mb-2">Import Type</h2>
          {importTypes.map((type) => (
            <label className="flex items-start mb-3" key={type.value}>
              <input
                checked={selectedImportType === type.value}
                className="mt-1 mr-3 accent-blue-500"
                name="importType"
                onChange={(e) => {
                  setSelectedImportType(e.target.value);
                  setCustomTag(generateDefaultTag(selectedSoftware, e.target.value));
                }}
                type="radio"
                value={type.value}
              />
              <div>
                <span className="font-medium text-gray-700">{type.label}</span>
                {type.description && (
                  <p className="text-sm text-gray-500">{type.description}</p>
                )}
              </div>
            </label>
          ))}
        </div>

        <h2 className="text-gray-600 font-medium">Custom Tag (optional)</h2>
        <label className="block mb-4">
          <input
            className="block w-full p-3 mt-2 border rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
            onChange={(e) => setCustomTag(e.target.value)}
            placeholder="Enter a custom tag for this upload"
            type="text"
            value={customTag}
          />
          {customTag && (
            <p className="mt-1 text-sm text-gray-500">
              Tag: {sanitizeTag(customTag)}
            </p>
          )}
        </label>

        <FileUploader
          handleChange={handleFileChange}
          maxSize={100}
          name="file"
          types={fileTypes}
        />
        {file && (
          <p className="mt-4 text-sm text-gray-600">
            Selected file: <span className="font-medium">{file.name}</span>
          </p>
        )}

        <button
          className={`w-full mt-6 p-3 rounded-lg font-medium text-white transition ${
            isUploading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
          disabled={!file || isUploading}
          onClick={handleUpload}
        >
          {isUploading ? "Uploading..." : "Upload"}
        </button>

        <div className="mt-6" id="upload-status">
          {isUploading && (
            <>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-500 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                {uploadProgress}% Complete
              </p>
            </>
          )}

          {uploadSuccess && (
            <p className="mt-4 text-green-500 font-medium">
              File uploaded successfully!
            </p>
          )}
          {uploadError && (
            <p className="mt-4 text-red-500 font-medium">{uploadError}</p>
          )}
        </div>
      </div>
    </MainPageWrapper>
  );
};
