import { Routes, Route, Navigate } from "react-router-dom";
import {
  HubRoutes,
  TrainingPortalRoutes,
  StochasticRoutes,
  AdminRoutes,
  CoachingRoutes,
} from "./routes";
import { TermsOfService } from "../components/TermsOfService/TermsOfService";
import Logout from "../modules/hub/features/Auth/components/Logout/Logout";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<Navigate replace to="/hub" />} path="/" />

      <Route
        element={<Navigate replace to="/certain-path/coaches-dashboard" />}
        path="/certain-path"
      />

      <Route
        element={<TermsOfService />}
        path="/certain-path/terms-of-service"
      />

      {HubRoutes}
      {TrainingPortalRoutes}
      {StochasticRoutes}
      {AdminRoutes}
      {CoachingRoutes}
      <Route element={<Logout />} path="/logout" />
    </Routes>
  );
}

export default AppRoutes;
