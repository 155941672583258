import { axiosInstance } from "../axiosInstance";
import { EditCourseTypeResponse, EditCourseTypeDTO } from "./types";

export const editCourseType = async (
  id: number,
  editCourseTypeDTO: EditCourseTypeDTO,
): Promise<EditCourseTypeResponse> => { 
  const response = await axiosInstance.put<EditCourseTypeResponse>(
    `/api/private/course-types/${id}/edit`,
    editCourseTypeDTO,
  );
  return response.data;
};
