import axios from "../axiosInstance";
import { DeleteCourseTypeResponse } from "./types";

export const deleteCourseType = async (
  id: number,
): Promise<DeleteCourseTypeResponse> => {
  const response = await axios.delete<DeleteCourseTypeResponse>(
    `/api/private/course-types/${id}`,
  );
  return response.data;
};
