import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCourse } from "@/api/createCourse/createCourseApi";
import { editCourse } from "@/api/editCourse/editCourseApi";
import { copyCourse } from "@/api/copyCourse/copyCourseApi";
import { editCourseType } from "@/api/editCourseType/editCourseTypeApi";
import { deleteCourse } from "@/api/deleteCourse/deleteCourseApi";
import { fetchCourses } from "@/api/fetchCourses/fetchCoursesApi";
import {
  CreateCourseRequest,
  CreateCourseResponse,
} from "@/api/createCourse/types";
import { EditCourseDTO } from "@/api/editCourse/types";
import { DeleteCourseResponse } from "@/api/deleteCourse/types";
import { FetchCoursesResponse, ApiCourse } from "@/api/fetchCourses/types";
import { RootState } from "@/app/rootReducer";
import { AppDispatch } from "@/app/store";
import { fetchCourseTypes } from "@/api/fetchCourseTypes/fetchCourseTypesApi";
import {
  setDialogCourseTypes,
  setDialogLoadingCourseTypes,
} from "../slices/coursesSlice";
import { ApiCourseType } from "@/api/fetchCourseTypes/types";
import { createCourseType } from "@/api/createCourseType/createCourseTypeApi";
import { deleteCourseType } from "@/api/deleteCourseType/deleteCourseTypeApi";
import { DeleteCourseTypeResponse } from "@/api/deleteCourseType/types";

// Fetch courses thunk
export const fetchCoursesAction = createAsyncThunk<
  FetchCoursesResponse,
  void,
  { state: RootState; rejectValue: string }
>("courses/fetchCourses", async (_, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const currentRequest = state.courseUI.currentRequest;
    const response = await fetchCourses(currentRequest);

    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create course thunk
export const createCourseAction = createAsyncThunk<
  CreateCourseResponse["data"],
  CreateCourseRequest,
  { rejectValue: string }
>("courses/createCourse", async (courseData, { rejectWithValue }) => {
  try {
    // Keep courseTypeId as is without any transformation
    const requestData: CreateCourseRequest = {
      ...courseData,
      courseTypeId: courseData.courseTypeId,
    };

    const response = await createCourse(requestData);
    return response.data;
  } catch (error) {
    return rejectWithValue(
      error instanceof Error ? error.message : "An unexpected error occurred",
    );
  }
});

// Edit course thunk
interface EditCourseActionPayload {
  courseId: number;
  editCourseDTO: EditCourseDTO;
  callback?: (updatedCourse: ApiCourse) => void;
}

export const editCourseAction = createAsyncThunk<
  ApiCourse,
  EditCourseActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/editCourse",
  async ({ courseId, editCourseDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await editCourse(courseId, editCourseDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Duplicate course thunk
interface DuplicateCourseActionPayload {
  courseId: number;
  courseCode: string;
  courseName: string;
  courseTypeId: number | null;
  callback?: (newCourse: ApiCourse) => void;
}

export const duplicateCourseAction = createAsyncThunk<
  ApiCourse,
  DuplicateCourseActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/duplicateCourse",
  async (
    { courseId, courseCode, courseName, courseTypeId, callback },
    { rejectWithValue },
  ) => {
    try {
      const response = await copyCourse(courseId, {
        courseCode,
        courseName,
        courseTypeId: courseTypeId ?? null,
      });
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

// Delete course thunk
export const deleteCourseAction = createAsyncThunk<
  DeleteCourseResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("courses/deleteCourse", async (courseId, { rejectWithValue }) => {
  try {
    const response = await deleteCourse(courseId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});

// Create course with company check thunk
export const createCourseWithCompanyCheckAction = createAsyncThunk<
  CreateCourseResponse["data"],
  Omit<
    CreateCourseRequest,
    "companyUuid" | "hideFromCalendar" | "hideFromCatalog"
  >,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/createCourseWithCompany",
  async (courseData, { dispatch, rejectWithValue }) => {
    try {
      const companyUuid = localStorage.getItem("selectedCompanyUuid");
      if (!companyUuid) {
        return rejectWithValue("No company selected");
      }

      // Keep the original courseTypeId without transformation
      const fullCourseData: CreateCourseRequest = {
        ...courseData,
        companyUuid,
        courseTypeId: courseData.courseTypeId, // Remove the Number() conversion
        hideFromCalendar: false,
        hideFromCatalog: false,
        isPublished: true,
      };

      const result = await dispatch(
        createCourseAction(fullCourseData),
      ).unwrap();
      return result;
    } catch (error) {
      console.error("createCourseWithCompanyCheckAction - Error:", error);
      return rejectWithValue(
        error instanceof Error ? error.message : "An unexpected error occurred",
      );
    }
  },
);

export const fetchCourseTypesAction = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("courses/fetchCourseTypes", async (_, { dispatch, rejectWithValue }) => {
  try {
    dispatch(setDialogLoadingCourseTypes(true));
    const response = await fetchCourseTypes({});
    dispatch(setDialogCourseTypes(response.data.items));
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  } finally {
    dispatch(setDialogLoadingCourseTypes(false));
  }
});

interface EditCourseTypeActionPayload {
  courseTypeId: number;
  editCourseTypeDTO: {
    name: string;
    description: string | null;
    isActive: boolean;
  };
  callback?: (updatedCourseType: ApiCourseType) => void;
}

export const editCourseTypeAction = createAsyncThunk<
  ApiCourseType,
  EditCourseTypeActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/editCourseType",
  async (
    { courseTypeId, editCourseTypeDTO, callback },
    { rejectWithValue },
  ) => {
    try {
      const response = await editCourseType(courseTypeId, editCourseTypeDTO);
      if (callback) {
        callback(response.data);
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

interface CreateCourseTypeDTO {
  name: string;
  description: string | null;
  isActive: boolean;
}

interface CreateCourseTypeActionPayload {
  createCourseTypeDTO: CreateCourseTypeDTO;
  callback?: (newCourseType: ApiCourseType) => void;
}

export const createCourseTypeAction = createAsyncThunk<
  ApiCourseType,
  CreateCourseTypeActionPayload,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>(
  "courses/createCourseType",
  async ({ createCourseTypeDTO, callback }, { rejectWithValue }) => {
    try {
      const response = await createCourseType(createCourseTypeDTO);
      if (callback) {
        callback(response.data.courseType);
      }
      return response.data.courseType;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  },
);

export const deleteCourseTypeAction = createAsyncThunk<
  DeleteCourseTypeResponse["data"],
  number,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>("courses/deleteCourseType", async (courseTypeId, { rejectWithValue }) => {
  try {
    const response = await deleteCourseType(courseTypeId);
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue("An unexpected error occurred");
  }
});
