import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import MainPageWrapper from "@/components/MainPageWrapper/MainPageWrapper";
import { uploadFieldServiceSoftware } from "@/api/uploadFieldServiceSoftware/uploadFieldServiceSoftwareApi";

const fileTypes = ["CSV", "XLSX", "XLS"];

const softwarePackages = [
  { value: "ServiceTitan", label: "ServiceTitan" },
  // { value: "FieldEdge", label: "Field Edge" },
  // { value: "Successware", label: "Successware" },
  // { value: "Other", label: "Other" },
];

const importTypes = [
  {
    value: "activeCustomers",
    label: "Active Customers",
    description: null,
  },
  {
    value: "invoices",
    label: "Invoices",
    description: null,
  },
];

interface TradeOption {
  label: string;
  value: string;
}

interface FieldServiceImportProps {
  supportedTrades?: TradeOption[];
  onUploadComplete?: (jobId: string) => void;
}

export const FieldServiceImport: React.FC<FieldServiceImportProps> = ({
  supportedTrades = [
    { label: "HVAC", value: "hvac" },
    { label: "Electrical", value: "electrical" },
    { label: "Plumbing", value: "plumbing" },
    { label: "Roofing", value: "roofing" },
  ],
}) => {
  const [selectedSoftware, setSelectedSoftware] = useState("");
  const [selectedTrade, setSelectedTrade] = useState("");
  const [selectedImportType, setSelectedImportType] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleFileChange = (file: File) => {
    setFile(file);
  };

  const handleUpload = async () => {
    if (!file || !selectedSoftware || !selectedImportType || !selectedTrade) {
      const message = "Please fill in all required fields";
      alert(message);
      toast.error(message); //Note from Chris: this doesn't appear to work.
      return;
    }

    const uploadFieldServiceSoftwareDTO = {
      file,
      software: selectedSoftware,
      trade: selectedTrade,
      importType: selectedImportType,
    };

    try {
      setUploadError(null);
      setUploadSuccess(false);
      setSelectedFile(file);
      setIsUploading(true);
      setUploadProgress(0);

      await uploadFieldServiceSoftware(
        uploadFieldServiceSoftwareDTO,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 1),
          );
          setUploadProgress(percentCompleted);
        },
      );
      setUploadSuccess(true);
      setSelectedFile(null);
    } catch (error) {
      toast.error("An error occurred during file upload");
      console.error("File upload error:", error);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (isUploading || uploadSuccess || uploadError) {
      document
        .getElementById("upload-status")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isUploading, uploadSuccess, uploadError]);

  return (
    <MainPageWrapper error={null} loading={false} title="Field Service Import">
      <div className="max-w-2xl mx-auto p-6">
        <select
          className="w-full p-2 mb-4 border rounded"
          onChange={(e) => setSelectedSoftware(e.target.value)}
          value={selectedSoftware}
        >
          <option value="">Select software package</option>
          {softwarePackages.map((software) => (
            <option key={software.value} value={software.value}>
              {software.label}
            </option>
          ))}
        </select>

        {supportedTrades.length > 1 && (
          <select
            className="w-full p-2 mb-4 border rounded"
            onChange={(e) => setSelectedTrade(e.target.value)}
            value={selectedTrade}
          >
            <option value="">Select trade</option>
            {supportedTrades.map((trade) => (
              <option key={trade.value} value={trade.value}>
                {trade.label}
              </option>
            ))}
          </select>
        )}

        <div className="mb-6">
          {importTypes.map((type) => (
            <div className="mb-2" key={type.value}>
              <label className="flex items-start">
                <input
                  checked={selectedImportType === type.value}
                  className="mt-1 mr-2"
                  name="importType"
                  onChange={(e) => setSelectedImportType(e.target.value)}
                  type="radio"
                  value={type.value}
                />
                <div>
                  <div>{type.label}</div>
                  {type.description && (
                    <div className="text-sm text-gray-500">
                      {type.description}
                    </div>
                  )}
                </div>
              </label>
            </div>
          ))}
        </div>

        <FileUploader
          handleChange={handleFileChange}
          maxSize={100}
          name="file"
          types={fileTypes}
        />

        {file && (
          <p className="mt-2 text-sm text-gray-600">
            Selected file: {file.name}
          </p>
        )}

        <button
          className="w-full mt-6 p-2 bg-blue-500 text-white rounded disabled:bg-gray-300"
          disabled={!file || isUploading}
          onClick={handleUpload}
        >
          {isUploading ? "Uploading and Processing: Please wait ..." : "Upload"}
        </button>
        <div className="mb-4" id="upload-status">
          {isUploading && (
            <div className="mt-4">
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-500 h-2.5 rounded-full"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <div className="mt-2 text-sm text-gray-500">
                {uploadProgress}%
              </div>
            </div>
          )}
        </div>
      </div>
    </MainPageWrapper>
  );
};
