// React and form imports
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useToastError } from "@/modules/trainingPortal/features/CourseManagement/hooks/useToastError";
import { useToastSuccess } from "@/modules/trainingPortal/features/CourseManagement/hooks/useToastSuccess";

// UI Components
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";

// Icons
import { Loader2, Pencil, Plus, Trash2 } from "lucide-react";

// Feature imports
import { CourseFormField } from "@/modules/trainingPortal/features/CourseManagement/components/CourseFormField/CourseFormField";
import {
  courseSchema,
  CourseFormData,
} from "@/modules/trainingPortal/features/CourseManagement/schemas/courseSchema";
import { COURSE_FORM } from "@/modules/trainingPortal/features/CourseManagement/constants/courseConstants";
import { CourseDialogProps } from "@/modules/trainingPortal/features/CourseManagement/types/types";
import {
  selectCourseDialogState,
  setDialogSubmitting,
  setDialogSubmitError,
  setDialogUnsavedChanges,
  setDialogReset,
} from "@/modules/trainingPortal/features/Courses/slices/coursesSlice";
import {
  fetchCourseTypesAction,
  deleteCourseTypeAction,
  createCourseWithCompanyCheckAction,
  editCourseAction,
} from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import { CourseTypeDialog } from "../CourseTypeDialog/CourseTypeDialog";
import {
  selectShowDeleteTypeDialog,
  selectShowAddCourseType,
  selectEditingCourseType,
  setShowAddCourseType,
  setShowDeleteTypeDialog,
  setEditingCourseType,
} from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";

const CourseDialog = ({
  open,
  onOpenChange,
  initialData,
  mode,
  onSuccess,
}: CourseDialogProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    isSubmitting,
    submitError,
    showUnsavedChangesDialog,
    showResetDialog,
    courseTypes,
    isLoadingCourseTypes,
  } = useAppSelector(selectCourseDialogState);
  const showDeleteTypeDialog = useAppSelector(selectShowDeleteTypeDialog);
  const showAddCourseType = useAppSelector(selectShowAddCourseType);
  const editingCourseType = useAppSelector(selectEditingCourseType);
  const { executeToastError } = useToastError();
  const { executeToastSuccess } = useToastSuccess();

  const form = useForm<CourseFormData>({
    resolver: zodResolver(courseSchema),
    defaultValues: initialData || COURSE_FORM.DEFAULT_VALUES,
    mode: "onChange",
  });

  useEffect((): void => {
    if (open) {
      if (mode === "add") {
        form.reset(COURSE_FORM.DEFAULT_VALUES);
      } else if (mode === "edit" && initialData) {
        form.reset(initialData);
      }
    }
  }, [open, mode, form]);

  const isDirty = form.formState.isDirty;

  const handleCourseTypeChange = (newValue: string) => {
    if (newValue === "add-new") {
      dispatch(setShowAddCourseType(true));
      return;
    }

    const numericValue = parseInt(newValue, 10);
    if (!isNaN(numericValue)) {
      form.setValue("courseTypeId", numericValue, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const handleSubmit = async (data: CourseFormData): Promise<void> => {
    try {
      dispatch(setDialogSubmitting(true));
      dispatch(setDialogSubmitError(null));

      await executeToastSuccess(
        async () => {
          if (mode === "add") {
            await dispatch(createCourseWithCompanyCheckAction(data)).unwrap();
          } else {
            await dispatch(
              editCourseAction({
                courseId: initialData?.id ?? 0,
                editCourseDTO: {
                  courseTypeId: data.courseTypeId ?? 0,
                  courseName: data.courseName,
                  courseCode: data.courseCode,
                  courseDescription: data.courseDescription,
                  coursePrice: data.coursePrice,
                  isPublished: true,
                },
              }),
            ).unwrap();
          }

          onOpenChange?.(false);
          onSuccess?.();
        },
        {
          title: "Success",
          description:
            mode === "add"
              ? "Course created successfully"
              : "Course updated successfully",
        },
      );
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setDialogSubmitError(error.message));
      }
    } finally {
      dispatch(setDialogSubmitting(false));
    }
  };

  const handleClose = (): void => {
    if (isDirty) {
      dispatch(setDialogUnsavedChanges(true));
    } else {
      onOpenChange(false);
    }
  };

  const handleConfirmClose = (): void => {
    form.reset();
    dispatch(setDialogUnsavedChanges(false));
    onOpenChange(false);
  };

  const handleCancelClose = (): void => {
    dispatch(setDialogUnsavedChanges(false));
  };

  const handleReset = (): void => {
    if (isDirty) {
      dispatch(setDialogReset(true));
    }
  };

  const handleConfirmReset = (): void => {
    if (mode === "add") {
      form.reset(COURSE_FORM.DEFAULT_VALUES);
    } else if (initialData) {
      form.reset(initialData);
    }
    dispatch(setDialogReset(false));
  };

  const handleCancelReset = (): void => {
    dispatch(setDialogReset(false));
  };

  useEffect(() => {
    if (open && (mode === "add" || mode === "edit")) {
      dispatch(fetchCourseTypesAction());
    }
  }, [open, mode, dispatch]);

  const handleDeleteCourseType = async () => {
    const selectedTypeId = Number(form.watch("courseTypeId"));
    console.log("Starting delete operation for type:", selectedTypeId);

    try {
      await executeToastSuccess(
        async () => {
          const result = await dispatch(
            deleteCourseTypeAction(selectedTypeId),
          ).unwrap();

          dispatch(setShowDeleteTypeDialog(false));

          if (form.watch("courseTypeId") === selectedTypeId) {
            form.setValue("courseTypeId", null, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }

          await Promise.all([
            dispatch(fetchCourseTypesAction()),
            onSuccess?.(),
          ]);

          return result;
        },
        {
          title: "Success",
          description:
            "Course type deleted successfully. Associated courses have been updated.",
        },
      );
    } catch (error) {
      console.error("Error in handleDeleteCourseType:", error);
      if (
        error instanceof Error &&
        error.message.includes("being used by one or more courses")
      ) {
        executeToastError(
          () => Promise.reject(error),
          "Cannot delete this course type because it is being used by existing courses",
        );
      } else {
        executeToastError(
          () => Promise.reject(error),
          "Failed to delete course type",
        );
      }
    }
  };

  return (
    <ErrorBoundary>
      <AlertDialog
        onOpenChange={(open) => dispatch(setDialogUnsavedChanges(open))}
        open={showUnsavedChangesDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {COURSE_FORM.TITLES.unsavedChanges}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {COURSE_FORM.MESSAGES.unsavedChanges}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelClose}>
              {COURSE_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmClose}>
              {COURSE_FORM.BUTTON_TEXT.discard}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        onOpenChange={(open) => dispatch(setDialogReset(open))}
        open={showResetDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{COURSE_FORM.TITLES.resetForm}</AlertDialogTitle>
            <AlertDialogDescription>
              {COURSE_FORM.MESSAGES.resetForm}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelReset}>
              {COURSE_FORM.BUTTON_TEXT.cancel}
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmReset}>
              {COURSE_FORM.BUTTON_TEXT.reset}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        onOpenChange={(open) => dispatch(setShowDeleteTypeDialog(open))}
        open={showDeleteTypeDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Course Type</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete this course type? Any courses
              using this type will have their course type removed. This action
              cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => dispatch(setShowDeleteTypeDialog(false))}
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteCourseType}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Sheet
        onOpenChange={(isOpen) => {
          if (!isOpen && !isSubmitting) {
            handleClose();
          }
        }}
        open={open}
      >
        <SheetContent>
          <SheetHeader>
            <SheetTitle>
              {mode === "add"
                ? COURSE_FORM.TITLES.add
                : COURSE_FORM.TITLES.edit}
            </SheetTitle>
          </SheetHeader>

          <Form {...form}>
            <form
              className="space-y-4 mt-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseName}
                name="courseName"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseName}
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseCode}
                name="courseCode"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseCode}
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.courseDescription}
                name="courseDescription"
                placeholder={COURSE_FORM.PLACEHOLDERS.courseDescription}
                type="textarea"
              />

              <CourseFormField
                control={form.control}
                label={COURSE_FORM.LABELS.coursePrice}
                name="coursePrice"
                placeholder={COURSE_FORM.PLACEHOLDERS.coursePrice}
                type="number"
              />

              <div className="flex gap-2 items-start">
                <div className="flex-1">
                  <CourseFormField
                    control={form.control}
                    disabled={isLoadingCourseTypes}
                    label={COURSE_FORM.LABELS.courseType}
                    name="courseTypeId"
                    selectProps={{
                      placeholder: isLoadingCourseTypes
                        ? "Loading course types..."
                        : "Select a course type",
                      options: [
                        ...courseTypes.map((type) => ({
                          value: type.id.toString(),
                          label: type.name,
                        })),
                        {
                          value: "add-new",
                          label: (
                            <div className="flex items-center text-green-600">
                              <Plus className="mr-2 h-4 w-4" />
                              Add new course type
                            </div>
                          ),
                        },
                      ],
                      onValueChange: handleCourseTypeChange,
                    }}
                    type="select"
                  />
                </div>
                <Button
                  className="mt-8"
                  disabled={!form.watch("courseTypeId")}
                  onClick={(e) => {
                    e.preventDefault();
                    const selectedTypeId = Number(form.watch("courseTypeId"));
                    const selectedType = courseTypes.find(
                      (type) => type.id === selectedTypeId,
                    );
                    if (selectedType) {
                      dispatch(setEditingCourseType(selectedType));
                    }
                  }}
                  size="icon"
                  type="button"
                  variant="outline"
                >
                  <Pencil className="h-4 w-4" />
                </Button>
                <Button
                  className="mt-8"
                  disabled={!form.watch("courseTypeId")}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setShowDeleteTypeDialog(true));
                  }}
                  size="icon"
                  type="button"
                  variant="outline"
                >
                  <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
              </div>

              {submitError && (
                <Alert variant="destructive">
                  <AlertTitle>{COURSE_FORM.ERROR_MESSAGES.title}</AlertTitle>
                  <AlertDescription>{submitError}</AlertDescription>
                </Alert>
              )}

              <div className="flex justify-between space-x-4 pt-4">
                <Button
                  disabled={isSubmitting || !isDirty}
                  onClick={handleReset}
                  type="button"
                  variant="outline"
                >
                  {COURSE_FORM.BUTTON_TEXT.reset}
                </Button>
                <div className="flex space-x-4">
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    type="button"
                    variant="outline"
                  >
                    {COURSE_FORM.BUTTON_TEXT.cancel}
                  </Button>
                  <Button
                    className="min-w-[100px]"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {mode === "add" ? "Creating..." : "Updating..."}
                      </>
                    ) : mode === "add" ? (
                      "Create"
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Form>
        </SheetContent>
      </Sheet>

      {editingCourseType && (
        <CourseTypeDialog
          courseType={editingCourseType}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setEditingCourseType(null));
          }}
          onSuccess={() => {
            dispatch(fetchCourseTypesAction());
          }}
          open={!!editingCourseType}
        />
      )}

      {showAddCourseType && (
        <CourseTypeDialog
          courseType={null}
          onOpenChange={(open: boolean) => {
            if (!open) dispatch(setShowAddCourseType(false));
          }}
          onSuccess={() => {
            dispatch(fetchCourseTypesAction());
          }}
          open={showAddCourseType}
        />
      )}
    </ErrorBoundary>
  );
};

export default CourseDialog;
