import { inputTypeSchema } from "../schemas/courseSchema";
import {
  UseCourseFormFieldProps,
  UseCourseFormFieldReturn,
} from "../types/types";

export const useCourseFormField = <T extends Record<string, unknown>>({
  type = "text",
}: UseCourseFormFieldProps<T>): UseCourseFormFieldReturn => {
  // Validate type prop
  const validatedType = inputTypeSchema.parse(type);

  return {
    validatedType,
  };
};
