import React, { useEffect, useCallback } from "react";
import { CourseManagementTableSection } from "../CourseManagementTableSection/CourseManagementTableSection";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/app/hooks";
import CourseDialog from "../CourseDialog/CourseDialog";
import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";
import { useCourseForm } from "../../hooks/useCourseForm";
import { fetchCoursesAction } from "@/modules/trainingPortal/features/Courses/thunks/courseThunks";
import {
  setCurrentRequest,
  updateCourseDialog,
} from "@/modules/trainingPortal/features/Courses/slices/courseUISlice";
import { ApiCourse } from "@/api/fetchCourses/types";
import {
  selectCoursesWithLoadingState,
  selectCourseDialogState,
  selectCurrentRequestState,
} from "../../selectors/courseManagementSelectors";
import { useToast } from "@/hooks/useToast";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useCourseManagementActions } from "../../hooks/useCourseManagementActions";
import { CourseFormData } from "../../schemas/courseSchema";
import { CourseManagementToolbar } from "../CourseManagementToolbar/CourseManagementToolbar";
import { TOAST_MESSAGES, DEFAULTS } from "../../constants/courseConstants";
import { CourseManagementContext } from "../../context/CourseManagementContext";

const CourseManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, isSubmitting } = useCourseForm();
  const { toast } = useToast();
  const { courses, isLoadingCourses, coursesError, totalCount } = useSelector(
    selectCoursesWithLoadingState,
  );
  const { showCourseDialog, courseToEdit } = useSelector(
    selectCourseDialogState,
  );
  const currentRequest = useSelector(selectCurrentRequestState);
  const {
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
  } = useCourseManagementActions(currentRequest);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        dispatch(
          setCurrentRequest({
            page: 1,
            pageSize: DEFAULTS.PAGE_SIZE,
          }),
        );
        await dispatch(fetchCoursesAction()).unwrap();
      } catch {
        toast({
          title: TOAST_MESSAGES.FETCH_ERROR.title,
          description: TOAST_MESSAGES.FETCH_ERROR.description,
          variant: "destructive",
        });
      }
    };

    fetchCourses();
  }, [dispatch]);

  const handleDialogSubmit = useCallback(
    async (data: CourseFormData) => {
      try {
        await handleSubmit(data, courseToEdit);
      } catch {
        toast({
          title: TOAST_MESSAGES.SUBMIT_ERROR.title,
          description: TOAST_MESSAGES.SUBMIT_ERROR.description,
          variant: "destructive",
        });
      }
    },
    [handleSubmit],
  );

  const handleAddCourse = useCallback(() => {
    dispatch(updateCourseDialog({ show: true, course: null }));
  }, [dispatch]);

  const handleEditCourse = useCallback(
    (course: ApiCourse) => {
      dispatch(updateCourseDialog({ show: true, course }));
    },
    [dispatch],
  );

  const contextValue = {
    currentRequest,
    isLoadingCourses,
    handlePaginationChange,
    handleSortingChange,
    handleSearchChange,
    handleFiltersChange,
    handleEditCourse,
  };

  if (coursesError) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{coursesError}</AlertDescription>
      </Alert>
    );
  }

  return (
    <ErrorBoundary>
      <CourseManagementContext.Provider value={contextValue}>
        <div className="space-y-4">
          <CourseManagementToolbar
            isLoading={isLoadingCourses}
            onAddCourse={handleAddCourse}
            onFilterChange={handleFiltersChange}
            onSearch={handleSearchChange}
          />
          <CourseManagementTableSection
            courses={courses}
            currentRequest={currentRequest}
            defaultPageSize={DEFAULTS.PAGE_SIZE}
            isLoading={isLoadingCourses}
            onEditCourse={handleEditCourse}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            totalCount={totalCount}
          />
        </div>
        <CourseDialog
          initialData={
            courseToEdit
              ? {
                  id: courseToEdit.id,
                  courseName: courseToEdit.courseName,
                  courseCode: courseToEdit.courseCode,
                  courseDescription: courseToEdit.courseDescription,
                  coursePrice: Number(courseToEdit.coursePrice),
                  courseTypeId: courseToEdit.courseTypeId || null,
                }
              : undefined
          }
          isSubmitting={isSubmitting}
          mode={courseToEdit ? "edit" : "add"}
          onOpenChange={(open) => {
            if (!open) {
              dispatch(updateCourseDialog({ show: false, course: null }));
            }
          }}
          onSubmit={handleDialogSubmit}
          onSuccess={() => {
            dispatch(fetchCoursesAction());
          }}
          open={showCourseDialog}
        />
      </CourseManagementContext.Provider>
    </ErrorBoundary>
  );
};

export default CourseManagement;
